import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import VideoEmbed from "../../components/video-embed"
import ActionBand from "../../components/action-band"
import DonateBar from "../../components/donate-bar"

export default class EquipALeader extends React.Component {
  render() {
    return (
      <Layout title="Equip a Leader" sectionName="Get Involved" sectionPath="/get-involved">
        <Seo title="Equip a Leader"/>
        <div className="container">
          <div className="row justify-content-center">
            <section className="col-sm-10">
              <VideoEmbed vimeoId="261842495" title="Equip a Leader" />

              <p>Thank you for your interest in equipping young Native Christian leaders to bring Christ's hope to their people. Shockingly high rates of suicide, abuse and family dysfunction are contributing to what has literally become a life-or-death battle. Sadly, government programs that are meant to bring hope and healing have had little impact.</p>

              <p>But ultimately the answer is not programs. It's people. Who embody the hope of Jesus and provide inspiring leadership.</p>

              <p>There is no more powerful weapon in the battle than developing fully equipped Native Christian leaders, who live and serve in Native communities. When you partner with us through Hope for Native America, you join us in developing and deploying change agents to hope-starved communities.</p>

              <p>Young people living on reservations need a ministry that's young and that's relevant. And, thank God, that's happening more than ever. I know, because I'm one of those being equipped to bring hope to my people.</p>

              <p>If you're a Native young person like me, there just aren't many places you can find Jesus on your reservation. In some cases, none. When you live in a "church on every corner" culture, it's hard to imagine followers of Jesus in this country who have few or no options to find Christian community. But the reality in many Native communities is that you may have to drive miles to even find a church – much less a thriving body of believers who can provide the encouragement and discipleship young believers need.</p>

              <p>But there are young Native believers like me who have had the opportunity to really be discipled into leadership. Because of people who give to make it all possible. Like serving on an On Eagles' Wings team of young Native Americans who bring the hope we've found to reservations across this continent. It's a place where leaders are born. And we are regularly leading Native young people to Christ and providing opportunities for them to be part of a Christ-centered community where they live.</p>

              <p>We're equipped to do this by receiving leadership training opportunities through Bible School scholarships, crisis counseling, regular leadership retreats and consistent community. There's even a Native-led team bringing the Hope of Jesus all year long to the young people of one of the least-reached Native communities.</p>

              <p>The places where many of serve – and come from – are often some of the poorest in the country. That's why your help is so important. It literally helps make all this possible. And raises up the Native Christians leaders our people have needed for so long.</p>

              <p>So when those with a heart for Native America wonder what they can do to make a difference, here's the question:</p>

              <blockquote>"What can I do to equip and support a Native leader? What can I do to stand with the person who is the best messenger to reach their people?"</blockquote>

              <p>When you give to this ministry monthly, you are investing a young Native leader who can be a living example of hope to broken young people. You are making it possible for indigenous Christian leadership to fight all year long for their young people and their communities. And to be the face and voice of Jesus for a lost generation. And THAT is hope for Native America!</p>

            </section>
          </div>
        </div>
        <ActionBand title="Equip a Leader">
          <DonateBar amount={25} isMonthly={true} campaignId={237228}>
            towards equipping a young Native American Christian leader to represent Jesus in their community.
          </DonateBar>
          <DonateBar amount={50} isMonthly={true} campaignId={237228}>
            towards equipping a young Native American Christian leader to represent Jesus in their community.
          </DonateBar>
          <DonateBar amount={100} isMonthly={true} campaignId={237228}>
            towards equipping a young Native American Christian leader to represent Jesus in their community.
          </DonateBar>
          <DonateBar isMonthly={true} campaignId={237228}>
            Any amount you can give helps!
          </DonateBar>
        </ActionBand>
      </Layout>
    )
  }
}

