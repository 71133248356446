import React from "react"
import PropTypes from "prop-types"
import rightArrow from '../images/right-arrow.png'

export default class DonateBar extends React.Component {
  render() {
    let displayAmount = (this.props.amount && this.props.amount > 0) ? '$' + this.props.amount : 'Other';
    let displayName = (this.props.amount && this.props.amount > 0) ? `Give $${this.props.amount}${this.props.isMonthly ? ' per month' : ''}` : 'Other';
    let campaignNumber = this.props.campaignId ? this.props.campaignId : '237270';
    let paramStr = ((this.props.amount && this.props.amount > 0) ? `?amount=${this.props.amount}` : '?amount=0') + (this.props.isMonthly ? '&recurring=1' : '');
    let linkUrl = `https://donate.h4na.org/give/${campaignNumber}/#!/donation/checkout${paramStr}`;

    return (
      <div className="donate-bar clearfix">
        <a className={this.props.isMonthly ? "donate-circle donate-monthly" : "donate-circle"} href={linkUrl}>
          {displayAmount}
          {this.props.isMonthly && <br />}
          {this.props.isMonthly && <span>Monthly</span>}
        </a>
        <a href={linkUrl}>
          <img src={rightArrow} alt="" />
        </a>
        <p className={this.props.noTopPadding ? "" : "short-stack"}>
          <a href={linkUrl}>
            <span className="donate-name">{displayName} - </span>
            {this.props.children}
          </a>
        </p>
      </div>
    )
  }
}

DonateBar.propTypes = {
  children: PropTypes.node.isRequired,
  campaignId: PropTypes.number,
  amount: PropTypes.number,
  isMonthly: PropTypes.bool,
  noTopPadding: PropTypes.bool,
}
