import React from "react"
import PropTypes from "prop-types"

export default class ActionBand extends React.Component {
  render() {
    return (
      <div className="action-band">
        <div className="container">
          <div className="row justify-content-center">
            <section className="col-sm-10">
              <h2>{this.props.title}</h2>
              {this.props.children}
            </section>
          </div>
        </div>

      </div>
    )
  }
}

ActionBand.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
}